#LinkToStore{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  .welcome-text{
    max-width: 320px;
    font-size: 24px;
    margin-bottom: 24px;
  }
}